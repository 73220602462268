<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 mt-10">
					<h5>List of Classmates</h5>
				</a-col>


			</a-row>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="8" class="mb-24 pb-10" v-for="memb in classMembers" :key="memb.uuid">
					<a-card class="pb-15">
                        <a class="text-dark" style="text-decoration: none !important;" :href="`/tutors/view/${memb.uuid}`">
                            <a-row :gutter="24">
                                <a-col :span="24" :xs="24" :sm="12" :md="8" :lg="8" :xl="8" class="text-center">
                                    <a-avatar :size="70" class="mt-5" :src="memb.profile" />
                                </a-col>

                                <a-col :span="24" :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                                    <a-row :gutter="24" class="pt-10">
                                        <a-col :span="24">
                                            <h6 class="mb-0 pb-0">{{ `${formatName(memb.firstName)} ${formatName(memb.lastName)}`}}</h6>
                                        </a-col>
                                        
                                        <a-col :span="24" class="mt-0">
                                            <span> {{ isCreator ? 'Tutor' : memb.isCoTutor ? 'Co-Tutor' : 'Student' }}</span>
                                            <!-- <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                            </svg>
                                            <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                            </svg> -->
                                        </a-col>
                                    </a-row> 

                                    <a-row :gutter="24" class="mt-5">
                                        <!-- <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            <small class="description">Teaches: <strong>Online, At Home</strong></small>
                                        </a-col> -->

                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-0 pt-0">
                                            <small>Last seen {{ $Moment(memb.lastSeen).format('DD MMM')}}</small>
                                        </a-col>
                                    </a-row>

                                    
                                </a-col>

                            </a-row>
                        </a>
                        
                    </a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

	</div>
</template>

<script>
	
	export default ({
		
		components: {
		},
        props: ['classDetails', 'isCreator'],
		data() {
			return {

				loadingData: false,

				classMembers: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 9,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getTutors();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getTutors()
                },
            },
            deep: true,
        },
		created() {
            this.initializeFilter();
			this.getTutors()
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getTutors();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getTutors();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getTutors();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getTutors();
            },

            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

			async getTutors() {

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let  url = `${this.$BACKEND_URL}/members?clazz=${this.$route.params.uuid}&populate=true&page=${currentPage}&size=${perPage}`;
                 
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classMembers = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
						this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

            getFewSubjectList(subjectsList) {
                let newList = [subjectsList[0], subjectsList[1]]

                return newList
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>